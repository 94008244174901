import React from "react";

import TableCustomLayout from "../../components/Tables/TableCustomLayout";
import TableHeadCustom from "../../components/Tables/TableHeadCustom";
import TableCellCustom from "../../components/Tables/TableCellCustom";
import TableRowCustom from "../../components/Tables/TableRowCustom";
import { TableBody } from "@mui/material";

export default function StatisticsByPartnerDetailed(props) {
  const headCells = [
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Data",
    },
    {
      id: "clicks",
      numeric: true,
      disablePadding: false,
      label: "Cliques",
    },
    {
      id: "registers",
      numeric: true,
      disablePadding: false,
      label: "Registros",
    },
    {
      id: "ftds",
      numeric: true,
      disablePadding: false,
      label: "FTDs",
    },
    {
      id: "ftdsVolume",
      numeric: true,
      disablePadding: false,
      label: "Soma de FTDs",
    },
    {
      id: "deposits",
      numeric: true,
      disablePadding: false,
      label: "Depósitos",
    },
    {
      id: "depositsVolume",
      numeric: true,
      disablePadding: false,
      label: "Soma de Depósitos",
    },
    {
      id: "withdrawalsVolume",
      numeric: true,
      disablePadding: false,
      label: "Soma de Saques",
    },
    {
      id: "givenBonus",
      numeric: true,
      disablePadding: false,
      label: "Bônus",
    },
    {
      id: "traders",
      numeric: true,
      disablePadding: false,
      label: "Traders Ativos",
    },
    {
      id: "totalOperatedVolume",
      numeric: true,
      disablePadding: false,
      label: "Volume Total",
    },
    {
      id: "revenueShare",
      numeric: true,
      disablePadding: false,
      label: "Volume Share",
    },
  ];

  return (
    <TableCustomLayout
      title="Estatísticas Detalhadas do Parceiro"
      loading={props.loading}
      data={props.data}
      page={props.page}
      rowsPerPage={props.rowsPerPage}
      onPageChange={props.onPageChange}
      metadata={props.metadata}
    >
      <TableHeadCustom headCells={headCells} onRequestSort={props.handleRequestSort} />
      <TableBody>
        {props.data.map((row, index) => (
          <TableRowCustom key={index}>
            <TableCellCustom align="center">{row.date}</TableCellCustom>
            <TableCellCustom align="center">{row.clicks}</TableCellCustom>
            <TableCellCustom align="center">{row.registers}</TableCellCustom>
            <TableCellCustom align="center">{row.ftds}</TableCellCustom>
            <TableCellCustom align="center">{row.ftdsVolume}</TableCellCustom>
            <TableCellCustom align="center">{row.deposits}</TableCellCustom>
            <TableCellCustom align="center">{row.depositsVolume}</TableCellCustom>
            <TableCellCustom align="center">{row.withdrawalsVolume}</TableCellCustom>
            <TableCellCustom align="center">{row.givenBonus}</TableCellCustom>
            <TableCellCustom align="center">{row.traders}</TableCellCustom>
            <TableCellCustom align="center">{row.totalOperatedVolume}</TableCellCustom>
            <TableCellCustom align="center">{row.revenueShare}</TableCellCustom>
          </TableRowCustom>
        ))}
      </TableBody>
    </TableCustomLayout>
  );
}