import { isMobile } from "react-device-detect";
import { cssTransition, toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import ToastPaper from "../components/Card/ToastPaper";

const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut",
  appendPosition: false,
  collapse: true,
});

const getToastStyle = (position) => {
  const baseStyle = {
    fontSize: isMobile ? 10 : 12,
    height: isMobile ? 50 : null,
  };

  if (position === "top-center") {
    return {
      ...baseStyle,
      fontWeight: "bold",
      justifyContent: "center",
      display: "flex",
      top: 365,
      padding: 0,
    };
  }

  return baseStyle;
};

const notify = (message, type, options) => {
  if (options?.modal) {
    const defaultProgressStyle =
      type === "success"
        ? { backgroundColor: "#08C58A" }
        : { backgroundColor: "#FF025C" };

    const progressStyle = { ...defaultProgressStyle, ...options?.style };

    const toastOptions = {
      style: getToastStyle(options?.position),
      theme: options?.theme || "dark",
      closeButton: false,
      position: "top-center",
      progressStyle,
      hideProgressBar: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      autoClose: options?.time || 2500,
      draggable: false,
      transition: bounce,
      className: options?.modal ? "Toastify__toast--modal" : "", // Adicionar a classe para modais
    };

    const uniqueToastId = uuidv4(); // Gerar um ID único para cada toast
    return toast(
      <ToastPaper result={options?.result} value={options?.value} />,
      { ...toastOptions, toastId: uniqueToastId }
    );
  } else {
    const defaultStyle = {
      backgroundColor: "#1a1a1a",
      color: "white",
      fontSize: 14,
      height: isMobile ? 50 : null,
    };

    const progressStyle = {
      backgroundColor: type === "success" ? "#08C58A" : "#FF025C",
    };

    const toastOptions = {
      closeButton: true,
      style: defaultStyle,
      position: "bottom-right",
      progressStyle,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      autoClose: options?.time || 2500,
    };

    if (type === "success") {
      toast.success(message, toastOptions);
    } else {
      toast.error(message, toastOptions);
    }
  }
};

export const notifySuccess = (message, options) => {
  notify(message, "success", options);
};

export const notifyError = (message, options) => {
  notify(message, "error", options);
};
