import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";

export default function ModalAdminChoice(props) {
  const { open, onClose, onConfirmNormal, onConfirmNew } = props;

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Escolha o Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box>
          <Typography variant="body1">
            Escolha qual painel você deseja acessar.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <Button
            variant="contained"
            sx={{ height: "30px", mb: 2, width: "50%" }}
            onClick={() => {
              onConfirmNormal();
              onClose();
            }}
          >
            Painel Admin
          </Button>
          <Button
            variant="contained"
            sx={{ height: "30px", mb: 2, width: "50%" }}
            onClick={() => {
              onConfirmNew();
              onClose();
            }}
          >
            Painel Partiner
          </Button>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button sx={{ mr: 1 }} variant="contained" onClick={onClose}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalAdminChoice.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirmNormal: PropTypes.func.isRequired,
  onConfirmNew: PropTypes.func.isRequired,
};
