import { Button, MenuItem, Select, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator={","}
      decimalScale={2}
      valueIsNumericString
      prefix="$"
    />
  );
});

export default function ModalAddCreditDebit(props) {
  const [selectedOption, setSelectedOption] = useState("credit");
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setSelectedOption("credit");
    setValue("");
    setMessage("");
    props.onClose();
  };
  const handleAddCreditDebit = () => {
    props.onConfirm({
      value,
      message,
      selectedOption,
    });
    handleClose();
  };

  return (
    <Modal show={props.open} onHide={handleClose}  hideBackdrop>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar crédito/débito</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-2">
          <div className="col-xl-12 col-sm-12">
            <div>
              <label htmlFor="environment">Ambiente</label>
            </div>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedOption}
              sx={{ width: "100%" }}
              onChange={(env) => {
                setSelectedOption(env.target.value);
              }}
            >
              <MenuItem value={"credit"}>Crédito</MenuItem>
              <MenuItem value={"debit"}>Débito</MenuItem>
              <MenuItem value={"deposit_p2p"}>Depósito - P2P</MenuItem>
            </Select>
          </div>
          <div className="col-xl-12 col-sm-12">
            <div>
              <label htmlFor="environment">Valor</label>
            </div>
            <TextField
              id="value"
              name="value"
              fullWidth
              value={value}
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              onChange={(e) => setValue(e.target.value)}
              variant="outlined"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-sm-12">
            <div>
              <label htmlFor="environment">Mensagem</label>
            </div>
            <TextField
              id="message"
              name="message"
              fullWidth
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              variant="outlined"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={() => handleAddCreditDebit()}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalAddCreditDebit.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};
