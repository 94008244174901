import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Box, Button, TextField, Typography } from "@mui/material";

export default function ModalUpdateLiquidityUser(props) {
  const [liquidityLimit, setLiquidityLimit] = useState(0)
  return (
    <Modal show={props.open} onHide={props.onClose}  hideBackdrop>
      <Modal.Header closeButton>
        <Modal.Title>Alterar limite do usuário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box>
          <TextField
            id="maxLiquidityPerOrder"
            name="maxLiquidityPerOrder"
            label="Liquidez máxima por pedido"
            fullWidth
            type="number"
            value={liquidityLimit}
            onChange={(e) => setLiquidityLimit(e.target.value)}
            variant="outlined"
          />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button sx={{ mr: 1 }} variant="contained" onClick={props.onClose}>
          Cancelar
        </Button>
        <Button variant="contained" color="warning" onClick={() => props.onConfirm(liquidityLimit)}>
          Alterar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalUpdateLiquidityUser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
