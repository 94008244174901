import { Button, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export default function ModalAddAffiliate(props) {
  const [email, setEmail] = useState("");

  const handleClose = () => {
    setEmail("");
    props.onClose();
  };

  const handleAddAffiliate = () => {
    props.onConfirm(email);
    handleClose();
  };

  return (
    <Modal show={props.open} onHide={handleClose} hideBackdrop>
      <Modal.Header closeButton>
        <Modal.Title>Adicionar afiliado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-2">
          <div className="col-xl-12 col-sm-12">
            <div>
              <label htmlFor="email">Email</label>
            </div>
            <TextField
              id="email"
              name="email"
              fullWidth
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleAddAffiliate}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalAddAffiliate.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};