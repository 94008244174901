import { useCallback, useEffect, useState } from "react";
import TableCustomLayout from "./TableCustomLayout";
import TableHeadCustom from "./TableHeadCustom";
import { TableBody, TableCell, TableRow } from "@mui/material";
import ReactLoading from "react-loading";
import { getComparator, stableSort } from "../../utils/stableSort";
import TableCellCustom from "./TableCellCustom";
import TableRowCustom from "./TableRowCustom";
import { Link } from "react-router-dom";
import TextCopy from "../TextCopy";

const DEFAULT_ORDER = "desc";
const DEFAULT_ORDER_BY = "partnerId";
const DEFAULT_ROWS_PER_PAGE = 10;

const headCells = [
  {
    id: "partnerId",
    numeric: false,
    disablePadding: false,
    label: "Código do Parceiro",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email do Parceiro",
  },
  {
    id: "balance",
    numeric: true,
    disablePadding: false,
    label: "Saldo dos Filhos",
  },
  {
    id: "deposits",
    numeric: true,
    disablePadding: false,
    label: "Total de Depósitos dos Filhos",
  },
  {
    id: "depositsVolume",
    numeric: true,
    disablePadding: false,
    label: "Volume de Depósitos dos Filhos",
  },
  {
    id: "childFtdsCount",
    numeric: true,
    disablePadding: false,
    label: "Total de FTDs",
  },
  {
    id: "childFtdsAmount",
    numeric: true,
    disablePadding: false,
    label: "Volume de FTDs",
  },
  {
    id: "withdrawals",
    numeric: true,
    disablePadding: false,
    label: "Total de Saques dos Filhos",
  },
  {
    id: "withdrawalsVolume",
    numeric: true,
    disablePadding: false,
    label: "Volume de Saques dos Filhos",
  },
  {
    id: "bonusesVolume",
    numeric: true,
    disablePadding: false,
    label: "Bônus dos Filhos",
  },
  {
    id: "revenueShare",
    numeric: true,
    disablePadding: false,
    label: "Revenue Share",
  },
  {
    id: "totalOperationsCount",
    numeric: true,
    disablePadding: false,
    label: "Total de Operações",
  },
  {
    id: "totalOperatedVolume",
    numeric: true,
    disablePadding: false,
    label: "Volume de Operações",
  },
  {
    id: "childrenRevenue",
    numeric: true,
    disablePadding: false,
    label: "Lucro dos Filhos",
  },
  {
    id: "depositSpreadFee",
    numeric: true,
    disablePadding: false,
    label: "Fee de Depósito",
  },
  {
    id: "withdrawalSpreadFee",
    numeric: true,
    disablePadding: false,
    label: "Fee de Saque",
  },
  {
    id: "totalPartnerRoi",
    numeric: true,
    disablePadding: false,
    label: "Total ROI Ebinex",
  },
  {
    id: "details",
    numeric: false,
    disablePadding: false,
    label: "Detalhes",
  },
];

let visibleRows = [];

export default function TableStatisticsByPartner(props) {
  const { onDownloadCvs, metadata, page, rowsPerPage, onPageChange, rows } =
    props;
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);

  useEffect(() => {
    if (Array.isArray(rows.data)) {
      let rowsOnMount = stableSort(
        rows.data,
        getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
      );
      rowsOnMount = rowsOnMount.slice(
        page * DEFAULT_ROWS_PER_PAGE,
        page * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
      );
      visibleRows = rowsOnMount;
    } else {
      visibleRows = [];
      console.log("Erro no useEffect: 'rows.data' não é um array.", rows.data);
    }
  }, [rows.data, page]);

  const handleRequestSort = useCallback(
    (event, newOrderBy) => {
      const isDesc = orderBy === newOrderBy && order === "desc";
      const toggledOrder = isDesc ? "asc" : "desc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      if (Array.isArray(rows.data)) {
        const sortedRows = stableSort(
          rows.data,
          getComparator(toggledOrder, newOrderBy)
        );
        const updatedRows = sortedRows.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );
        visibleRows = updatedRows;
      } else {
        console.error(
          "Erro no handleRequestSort: 'rows.data' não é um array.",
          rows.data
        );
      }
    },
    [orderBy, order, page, rowsPerPage, rows.data]
  );

  return (
    <TableCustomLayout
      title="Estatísticas por Parceiro"
      data={visibleRows}
      onDownloadCvs={onDownloadCvs}
      metadata={metadata}
      page={page}
      rowsPerPage={rowsPerPage}
      onPageChange={onPageChange}
    >
      <TableHeadCustom
        order={order}
        orderBy={orderBy}
        headCells={headCells}
        onRequestSort={handleRequestSort}
        align="center"
      />
      <TableBody>
        {Array.isArray(visibleRows) &&
        visibleRows.length > 0 &&
        !props.loading ? (
          visibleRows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRowCustom
                tabIndex={-1}
                key={row.partnerId}
                sx={{ cursor: "pointer" }}
              >
                <TableCellCustom
                  align="center"
                  component="th"
                  id={labelId}
                  scope="row"
                >
                  {row.partnerId}
                </TableCellCustom>
                <TableCellCustom align="center">
                  <TextCopy
                    text={{ id: String(row.email), value: row.email }}
                  />
                </TableCellCustom>
                <TableCellCustom align="center">{row.balance}</TableCellCustom>
                <TableCellCustom align="center">{row.deposits}</TableCellCustom>
                <TableCellCustom align="center">
                  {row.depositsVolume}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.childFtdsCount}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.childFtdsAmount}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.withdrawals}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.withdrawalsVolume}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.bonusesVolume}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.revenueShare}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.totalOperationsCount}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.totalOperatedVolume}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.childrenRevenue}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.depositSpreadFee}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.withdrawalSpreadFee}
                </TableCellCustom>
                <TableCellCustom align="center">
                  {row.totalPartnerRoi}
                </TableCellCustom>
                <TableCellCustom align="center">
                  <Link to={`/statistics/partners/${row.partnerId}`}>
                    Detalhes
                  </Link>
                </TableCellCustom>
              </TableRowCustom>
            );
          })
        ) : (
          <>
            {props.loading && (
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={6}>
                  <ReactLoading
                    type={"cylon"}
                    color="#ab47bc"
                    height={100}
                    width={100}
                  />
                </TableCell>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
              </TableRow>
            )}
            {!props.loading && visibleRows && visibleRows.length === 0 && (
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell align="start" rowSpan={6}>
                  Nenhum item.
                </TableCell>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
              </TableRow>
            )}
          </>
        )}
      </TableBody>
    </TableCustomLayout>
  );
}
