import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";

import "react-datepicker/dist/react-datepicker.css";
import SimbolsChipSeletc from "../../components/Selects/SimbolsChipSeletc";
import TimeFramesChipSeletc from "../../components/Selects/TimeFramesChipSeletc";
import FiltersByInterval from "./FiltersByInterval";
import { SYMBOL_TYPE, intervalData } from "../../utils/constants";
import { useCallback, useMemo, useState } from "react";
import { format } from "date-fns";

const Filters = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo,
  environment,
  setEnvironment,
  setCandleTimeFrames,
  candleTimeFrames = [],
  setSymbols,
  symbols = [],
  setSymbolType,
  symbolType,
  binaryOrderTypes,
  setBinaryOrderTypes,
  onClick,
  disable,
  loading,
}) => {
  console.log({
    symbols,
    symbolType,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [interval, setInterval] = useState("today");

  const setRangeInterval = useCallback(
    (interval) => {
      if (intervalData[interval]) {
        setDateFrom(intervalData[interval].startDate);
        setDateTo(new Date(Date.now()));
      }
    },
    [setDateFrom, setDateTo]
  );

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      mb={2}
    >
      <Grid item xs={6} md={3}>
        <FormControl fullWidth sx={{ minWidth: 120 }}>
          <InputLabel id="select-type">Ambiente</InputLabel>
          <Select
            labelId="select-type"
            id="select-type"
            name="environment"
            onChange={(env) => {
              setEnvironment(env.target.value);
            }}
            value={environment}
          >
            <MenuItem value={"REAL"}>REAL</MenuItem>
            <MenuItem value={"TEST"}>TEST</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6} md={3}>
        <FormControl fullWidth sx={{ minWidth: 120 }}>
          <InputLabel id="symbol-type">Tipo de ativo</InputLabel>
          <Select
            labelId="symbol-type"
            id="symbol-type"
            name="symbolType"
            onChange={(env) => {
              setSymbolType(env.target.value);
            }}
            value={symbolType}
          >
            {SYMBOL_TYPE.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {setSymbols && (
        <Grid item xs={6} md={3}>
          <SimbolsChipSeletc
            {...{
              symbolType,
              symbols,
              setSymbols,
            }}
          />
        </Grid>
      )}
      {setCandleTimeFrames && (
        <Grid item xs={6} md={3}>
          <TimeFramesChipSeletc
            {...{
              timeFrames: candleTimeFrames,
              setCandleTimeFrames,
            }}
          />
        </Grid>
      )}
      {setBinaryOrderTypes && (
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="binaryOrderTypes-label">Tipos de Ordens Binárias</InputLabel>
            <Select
              labelId="binaryOrderTypes-label"
              multiple
              value={binaryOrderTypes}
              onChange={(e) => setBinaryOrderTypes(e.target.value)}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="OPTION">OPTION</MenuItem>
              <MenuItem value="RETRACTION_DURATION">RETRACTION DURATION</MenuItem>
              <MenuItem value="RETRACTION_ENDTIME">RETRACTION ENDTIME</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={6} md={3}>
        <FiltersByInterval
          interval={interval}
          setInterval={setInterval}
          setRangeInterval={setRangeInterval}
          disableAllPeriod
          customDateFrom={dateFrom}
          customDateTo={dateTo}
          onChangeCustomDate={(e) => {
            setDateFrom(e.dateFrom);
            setDateTo(e.dateTo);
          }}
          sx={{
            width: "100%",
          }}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Button
          fullWidth={isMobile}
          variant="contained"
          disabled={disable || loading}
          onClick={onClick}
        >
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress sx={{ color: "#fff" }} />
            </Box>
          ) : (
            "Aplicar"
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Filters;
