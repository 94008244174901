import * as React from "react";
import PropTypes from "prop-types";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ReactLoading from "react-loading";
import numeral from "numeral";
import Chip from "../Chip";
import { styled, useMediaQuery } from "@mui/material";
import TableCustomLayout from "./TableCustomLayout";
import TableHeadCustom from "./TableHeadCustom";
import TableCellCustom from "./TableCellCustom";
import TableRowCustom from "./TableRowCustom";
import TextCopy from "../TextCopy";
import { MdOpenInNew } from "react-icons/md";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "balance",
    numeric: true,
    disablePadding: false,
    label: "Saldo atual",
  },
  {
    id: "operationsCount",
    numeric: true,
    disablePadding: false,
    label: "Total de operações",
  },
  {
    id: "winCount",
    numeric: true,
    disablePadding: false,
    label: "Total de vitórias",
  },
  {
    id: "loseCount",
    numeric: true,
    disablePadding: false,
    label: "Total de derrotas",
  },
  {
    id: "refundCount",
    numeric: true,
    disablePadding: false,
    label: "Total de reembolsos",
  },
  {
    id: "liquidWin",
    numeric: true,
    disablePadding: false,
    label: "Lucro Bruto",
  },
  {
    id: "paidFees",
    numeric: true,
    disablePadding: false,
    label: "Taxas pagas",
  },
  {
    id: "liquidLoss",
    numeric: true,
    disablePadding: false,
    label: "Perda Bruta",
  },
  {
    id: "totalAccept",
    numeric: true,
    disablePadding: false,
    label: "Volume total",
  },
  {
    id: "liquidRevenue",
    numeric: true,
    disablePadding: false,
    label: "Resultado Líquido",
  },
  {
    id: "online",
    numeric: true,
    disablePadding: false,
    label: "Online",
  },
  {
    id: "referralId",
    numeric: true,
    disablePadding: false,
    label: "ID de referência",
  },
];

const DEFAULT_ORDER = "desc";
const DEFAULT_ORDER_BY = "balance";
const DEFAULT_ROWS_PER_PAGE = 10000;

const host = window.location.host.includes("localhost")
  ? `http://${window.location.host}`
  : `https://${window.location.host}`;

let visibleRows = [];
export default function TableStatistics(props) {
  const [order, setOrder] = React.useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    let rowsOnMount = stableSort(
      props.rows,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
    );

    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );

    visibleRows = rowsOnMount;
  }, [props.rows]);

  const handleRequestSort = React.useCallback(
    (event, newOrderBy) => {
      console.log({ order, orderBy, rows: props.rows });
      const isDesc = orderBy === newOrderBy && order === "desc";
      const toggledOrder = isDesc ? "asc" : "desc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(
        props.rows,
        getComparator(toggledOrder, newOrderBy)
      );
      const updatedRows = sortedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

      console.log({ toggledOrder, newOrderBy });

      visibleRows = updatedRows;
    },
    [props.rows, order, orderBy]
  );

  return (
    <TableCustomLayout
      title="Estatisticas"
      data={visibleRows}
      metadata={props.metadata}
      onDownloadCvs={props.onDownloadCvs}
      page={props.page}
      rowsPerPage={props.rowsPerPage}
      onPageChange={props.onPageChange}
    >
      <TableHeadCustom
        order={order}
        orderBy={orderBy}
        headCells={headCells}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        {visibleRows && visibleRows.length > 0 && !props.loading ? (
          visibleRows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRowCustom
                tabIndex={-1}
                key={row.index}
                sx={{ cursor: "pointer" }}
              >
                <TableCellCustom
                  align="start"
                  component="th"
                  id={labelId}
                  scope="row"
                >
                  <Box display={"flex"} alignItems="center" gap={1}>
                    <TextCopy
                      text={{ id: String(row.email), value: row.email }}
                    />
                    <a
                      href={`${host}/users/${row.accountId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MdOpenInNew size={16} />
                    </a>
                  </Box>
                </TableCellCustom>
                <TableCellCustom align="start">
                  {numeral(row.balance).format("0,0.00")}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {row.operationsCount}
                </TableCellCustom>
                <TableCellCustom align="start">
                  <Chip className="win" value={row.winCount} />
                </TableCellCustom>
                <TableCellCustom align="start">
                  {" "}
                  <Chip className="lose" value={row.loseCount} />
                </TableCellCustom>
                <TableCellCustom align="start">
                  <Chip className="refunded" value={row.refundCount} />
                </TableCellCustom>
                <TableCellCustom align="start">
                  {numeral(row.liquidWin).format("0,0.00")}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {numeral(row.paidFees).format("0,0.00")}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {numeral(row.liquidLoss).format("0,0.00")}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {numeral(row.totalAccept).format("0,0.00")}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {numeral(row.liquidRevenue).format("0,0.00")}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {row.online ? (
                    <Chip className="win" value={"Online"} />
                  ) : (
                    "Offline"
                  )}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {row.referralId ? (
                    <a
                      href={`${host}/users/referral/${row.referralId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.referralId}
                    </a>
                  ) : (
                    ""
                  )}
                </TableCellCustom>
              </TableRowCustom>
            );
          })
        ) : (
          <>
            {props.loading && (
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell rowSpan={6}>
                  <ReactLoading
                    type={"cylon"}
                    color="#ab47bc"
                    height={100}
                    width={100}
                  />
                </TableCell>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
              </TableRow>
            )}
            {!props.loading && visibleRows && visibleRows.length === 0 && (
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell align="start" rowSpan={6}>
                  Nenhum item.
                </TableCell>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
              </TableRow>
            )}
          </>
        )}
      </TableBody>
    </TableCustomLayout>
  );
}

TableStatistics.propTypes = {
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  metadata: PropTypes.object,
  onDownloadCvs: PropTypes.func,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};
