import { useCallback, useEffect, useState } from "react";
import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import csvDownload from "json-to-csv-export";

import FiltersByInterval from "../../dashboard/FiltersByInterval";
import { getStatisticsByPartnerAndDate, getSingleUserSummarys } from "../../services/ReportService";
import StatisticsByPartnerDetailed from "../../../components/Tables/TableStatisticsByPartnerDetailed";
import { useParams } from "react-router";
import { intervalData } from "../../../utils/constants";
import StatiscticsUser from "../../../components/User/StataisticsCard";
import { getComparator, stableSort } from "../../../utils/stableSort";

const INIT_STATISTIC = {
    accountId: "",
    email: "",
    userData: {
        id: "",
        email: "",
        name: "",
        address: {
            address: "",
            city: "",
            state: null,
            country: "",
            zipcode: "",
        },
        phone: "",
        documentType: "",
        documentValue: "",
        createdAt: "",
        lastLogin: "",
        testUser: false,
        userCategory: "",
        using2fa: false,
    },
    balance: 0,
    operationsCount: 0,
    winCount: 0,
    loseCount: 0,
    refundCount: 0,
    liquidWin: 0,
    liquidLoss: 0,
    paidFees: 0,
    totalAccept: 0,
    liquidRevenue: 0,
    online: true,
};

const DEFAULT_ORDER = "desc";
const DEFAULT_ORDER_BY = "date";

export default function PartnerDetails() {
    const { partnerId } = useParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [loading, setLoading] = useState(true);

    const [statistics, setStatistics] = useState([]);
    const [userStatistics, setUserStatistics] = useState(INIT_STATISTIC);
    const [dateFrom, setDateFrom] = useState(intervalData["30d"].startDate); 
    const [dateTo, setDateTo] = useState(new Date(Date.now()));
    const [interval, setInterval] = useState("30d");
    const [metadata, setMetadata] = useState({});
    const [accountId, setAccountId] = useState("");
    const [order, setOrder] = useState(DEFAULT_ORDER);
    const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);

    const handleRequestSingleUserSummary = async (accountId) => {
        try {
            setLoading(true);
            const res = await getSingleUserSummarys({ accountId, dateFrom, dateTo });
            setUserStatistics(res.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handlePartnerStatisticsRequest = useCallback(async () => {
        try {
            setLoading(true);
            const statsRes = await getStatisticsByPartnerAndDate({
                partnerId,
                environment: "REAL",
                dateFrom,
                dateTo,
            });

            setStatistics(statsRes.data);
            setMetadata({ totalElements: statsRes.data.totalElements });
            if (statsRes.data.length > 0) {
              setAccountId(statsRes.data[0].accountId);
              handleRequestSingleUserSummary(statsRes.data[0].accountId);
            }
            setLoading(false);
        } catch (error) {
            console.error("Erro ao buscar estatísticas:", error);
        }
    }, [partnerId, dateFrom, dateTo]);

    const setRangeInterval = useCallback((interval) => {
        if (intervalData[interval]) {
            setDateFrom(intervalData[interval].startDate);
            setDateTo(new Date(Date.now()));
        }
    }, [setDateFrom, setDateTo]);

    useEffect(() => {
        handlePartnerStatisticsRequest();
    }, [partnerId, dateFrom, handlePartnerStatisticsRequest]);

    const requestCsvPartnerStatisticsDonwload = () => {
        const dateFrom = intervalData[interval === "custom" ? "all" : interval].startDate;
        const dateTo = new Date(Date.now());
        getStatisticsByPartnerAndDate({ partnerId, environment: "REAL", dateFrom, dateTo })
            .then((it) => {
                const { data } = it;
                csvDownload({
                    data,
                    filename: `Partner Statistics | ${dateFrom} - ${dateTo}.csv`,
                });
            })
            .catch((error) => {
                console.error("Erro ao gerar CSV:", error);
            });
    };


    const handleRequestSort = useCallback((event, newOrderBy) => {
      const isDesc = orderBy === newOrderBy && order === "desc";
      const toggledOrder = isDesc ? "asc" : "desc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
      const sortedRows = stableSort(statistics, getComparator(toggledOrder, newOrderBy));
      setStatistics(sortedRows);
    }, [order, orderBy, statistics]);

    return (
        <>
            <Stack
                sx={{ mb: 3 }}
                direction={isMobile ? "column" : "row"}
                spacing={2}
                justifyContent={"flex-end"}
            >
                <FiltersByInterval
                    interval={interval}
                    setInterval={setInterval}
                    setRangeInterval={setRangeInterval}
                    disableAllPeriod
                    customDateFrom={dateFrom}
                    customDateTo={dateTo}
                    onChangeCustomDate={(e) => {
                        setDateFrom(e.dateFrom);
                        setDateTo(e.dateTo);
                    }}
                />
            </Stack>
            <Grid item xs={12}>
                <StatiscticsUser
                    userStatistics={userStatistics}
                    accountId={accountId}
                    onRefresh={handleRequestSingleUserSummary}
                    ReportView={true}
                />
            </Grid>

            <Box sx={{ mt: 4 }}>
                <StatisticsByPartnerDetailed
                    data={statistics}
                    loading={loading}
                    onDownloadCvs={requestCsvPartnerStatisticsDonwload}
                    metadata={metadata}
                    handleRequestSort={handleRequestSort}
                />
            </Box>
        </>
    );
}