import { Button, MenuItem, Select, InputLabel, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getAllFlags } from "../../app/services/UserService";
import { notifyError } from "../../utils/toast";

export default function ModalUserFlags(props) {
  const [availableFlags, setAvailableFlags] = useState([]);
  const [selectedFlag, setSelectedFlag] = useState("");
  const [flagValue, setFlagValue] = useState(false);

  useEffect(() => {
    if (props.open) {
      getAllFlags().then((response) => {
        setAvailableFlags(response.data);
      });
    }
  }, [props.open]);

  const handleClose = () => {
    setSelectedFlag("");
    setFlagValue(false);
    props.onClose();
  };

  const handleSaveFlag = () => {
    if (selectedFlag) {
      props.onConfirm({ key: selectedFlag, value: flagValue });
      handleClose();
    } else {
      notifyError("Por favor, selecione uma flag.");
    }
  };

  return (
    <Modal show={props.open} onHide={handleClose} hideBackdrop>
      <Modal.Header closeButton>
        <Modal.Title>Definir flags</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-2">
          <div className="col-xl-12 col-sm-12">
            <FormControl fullWidth variant="outlined" className="mb-2">
              <InputLabel id="select-flag-label">Selecione uma flag</InputLabel>
              <Select
                labelId="select-flag-label"
                value={selectedFlag}
                onChange={(e) => setSelectedFlag(e.target.value)}
                label="Selecione uma flag"
              >
                {availableFlags.map((flag) => (
                  <MenuItem key={flag} value={flag}>
                    {flag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={flagValue}
                  onChange={(e) => setFlagValue(e.target.checked)}
                  color="primary"
                />
              }
              label="Ativar flag"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="contained" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleSaveFlag}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalUserFlags.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
