import { useCallback, useEffect, useState } from "react";
import { Box, Button, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import csvDownload from "json-to-csv-export";
import TableStatisticsByPartner from "../../components/Tables/TableStatisticsByPartner";

import { intervalData } from "../../utils/constants";
import FiltersByInterval from "../dashboard/FiltersByInterval";
import { getStatisticsByPartner } from "../services/ReportService";


export default function Partners() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(true);

  const [statistics, setStatistics] = useState([]);
  const [dateFrom, setDateFrom] = useState(intervalData["30d"].startDate); 
  const [dateTo, setDateTo] = useState(new Date(Date.now()));
  const [interval, setInterval] = useState("30d");
  const [page, setPage] = useState(0);
  const [metadata, setMetadata] = useState({});
  const [email, setEmail] = useState("");
  const [submittedEmail, setSubmittedEmail] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(event);
    handlePartnerStatisticsRequest(newPage);
  };

  const handlePartnerStatisticsRequest = useCallback(async (page) => {
    try {
      setLoading(true);
      const statsRes = await getStatisticsByPartner({
        dateFrom,
        dateTo,
        page,
        size: 1,
        email: submittedEmail,
      });
      setStatistics(statsRes.data);
      setPage(statsRes.data.page);
      setMetadata({totalElements: statsRes.data.totalElements});
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar estatísticas:", error);
      setLoading(false);
    }
  }, [dateFrom, dateTo, submittedEmail]);

  const setRangeInterval = useCallback(
    (interval) => {
      if (intervalData[interval]) {
        setDateFrom(intervalData[interval].startDate);
        setDateTo(new Date(Date.now()));
      }
    },
    [setDateFrom, setDateTo]
  );

  useEffect(() => {
    setLoading(false);
   if (submittedEmail) {
      handlePartnerStatisticsRequest(0);
    }
  }, [dateFrom, handlePartnerStatisticsRequest, submittedEmail]);

  const requestCsvPartnerStatisticsDonwload = () => {
    const dateFrom = intervalData[interval === "custom" ? "all" : interval].startDate;
    const dateTo = new Date(Date.now());
    getStatisticsByPartner({ dateFrom, dateTo, page, size: 1, email: submittedEmail })
      .then((it) => {
        const { data } = it.data;
        csvDownload({
          data,
          filename: `Partner Statistics | ${dateFrom} - ${dateTo}.csv`,
        });
      })
      .catch((error) => {
        console.error("Erro ao gerar CSV:", error);
      });
  };

  const handleSubmit = () => {
    if (email) {
      setSubmittedEmail(email);
    }
  };

  return (
    <>
      <Stack
        sx={{ mb: 3 }}
        direction={isMobile ? "column" : "row"}
        spacing={2}
        justifyContent={"flex-end"}
      >
        <TextField
          label="Email"
          variant="outlined"
          size="medium"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
        />
        <Button
          fullWidth={isMobile}
          variant="contained"
          disabled={!email}
          onClick={handleSubmit}
        >
          Aplicar
        </Button>
        <FiltersByInterval
          interval={interval}
          setInterval={setInterval}
          setRangeInterval={setRangeInterval}
          disableAllPeriod
          customDateFrom={dateFrom}
          customDateTo={dateTo}
          onChangeCustomDate={(e) => {
            setDateFrom(e.dateFrom);
            setDateTo(e.dateTo);
          }}
        />
      </Stack>

      <Box sx={{ mt: 4 }}>
        <TableStatisticsByPartner
          rows={statistics}
          loading={loading}
          rowsPerPage={metadata.totalElements}
          onDownloadCvs={requestCsvPartnerStatisticsDonwload}
          page={page}
          onPageChange={handleChangePage}
          metadata={metadata}
        />
      </Box>
    </>
  );
}