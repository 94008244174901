import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";

export default function ModalManualActivateUser(props) {
  return (
    <Modal show={props.open} onHide={props.onClose} hideBackdrop>
      <Modal.Header closeButton>
        <Modal.Title>Ativar Usuário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box>
          <Typography>Você realmente deseja ativar o usuário?</Typography>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button sx={{ mr: 1 }} variant="contained" onClick={props.onClose}>
          Cancelar
        </Button>
        <Button variant="contained" color="warning" onClick={props.onConfirm}>
          Ativar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalManualActivateUser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  userId: PropTypes.string.isRequired,
};
