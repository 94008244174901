import axios from "axios";
import { getBaseUrl } from "./BaseService";

export function addCredit({ accountId, asset, value, message }) {
  var finalUrl =
    getBaseUrl() +
    `/admin/bank/addCredit?accountId=${accountId}&asset=${asset}&value=${value}&message=${message}`;
  return axios.post(finalUrl);
}

export function addP2pDeposit({ accountId, asset, value, message }) {
  var finalUrl =
    getBaseUrl() +
    `/admin/bank/addP2pDeposit?accountId=${accountId}&asset=${asset}&value=${value}&message=${message}`;
  return axios.post(finalUrl);
}

export function addDebit({ accountId, asset, value, message }) {
  var finalUrl =
    getBaseUrl() +
    `/admin/bank/addDebit?accountId=${accountId}&asset=${asset}&value=${value}&message=${message}`;
  return axios.post(finalUrl);
}

