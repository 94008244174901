import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { VscCopy } from "react-icons/vsc";

const TextCopy = ({ text }) => {
  const [copied, setCopied] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const value = typeof text === "string" ? text : text.value;
  const id = typeof text === "object" ? text.id : undefined;

  if (!value) return <span>N/A</span>;

  const isInfoCopiadEqual = copied === (id || value);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(id || value);

    setTimeout(() => {
      if (isMounted.current) {
        setCopied(null);
      }
    }, 2000);
  };

  const divStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.25rem",
    "& .link-cell": {
      cursor: "pointer",
      padding: "0.75rem 0",
      transition: "transform 0.4s",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&.copied": {
        color: "#0ab575",
        "& svg": {
          color: "#0ab575",
        },
      },
      "&:hover": {
        color: "#0ab575",
        transform: "translateY(-4px)",

        "& svg": {
          color: "#0ab575",
        },
      },
    },
  };

  return (
    <Box sx={divStyle} title={value}>
      <Box
        flex={1}
        className={isInfoCopiadEqual ? "copied link-cell" : "link-cell"}
        onClick={handleCopy}
      >
        {copied === (id || value) ? `Copiado!` : value}
        <VscCopy size={16} onClick={handleCopy} />
      </Box>
    </Box>
  );
};

export default TextCopy;
